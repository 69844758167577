<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>教师排课</el-breadcrumb-item>
        <el-breadcrumb-item>新增排课</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-form ref="form" :model="form" style="padding: 48px 72px" label-position="right" label-width="auto">
      <el-form-item label="选择课程" prop="course_id" :rules="[{ required: true,trigger: 'blur', message: '姓名不能为空'},]">
        <el-select v-model="form.course_id" style="width: 240px" filterable placeholder="请选择" @change="selectCourse">
          <el-option
              v-for="item in courseList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <span>
            <el-button slot="reference" class="margin-l-sm" type="primary" size="mini" @click="$router.push('/courseList')">课程管理</el-button>
        </span>

      </el-form-item>

      <el-form-item label="选择校区" prop="school" :rules="[{ required: true,trigger: 'blur', message: '校区不能为空'},]">
        <el-select v-model="form.school" size="small" style="width: 240px" placeholder="请选择校区">
          <el-option label="深圳总部校区" value="深圳总部校区"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="上课老师" prop="teacher_uid" :rules="[{ required: true,trigger: 'blur', message: '上课老师不能为空'},]">
        <el-select v-model="form.teacher_uid" style="width: 240px" filterable placeholder="请选择">
          <el-option
              v-for="item in teacherList"
              :key="item.id"
              :disabled="item.status==0?true:false"
              :label="item.name"
              :value="item.id">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.status==1?'在职':'离职' }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="助教老师uid">
        <div  class="margin-b-sm"
              style="display:
                  flex;justify-content: space-between;align-items: center"
              v-for="(item,index) in form.helpers"
              :key="index"
        >
          <el-input style="width: 240px" v-model.number="form.helpers[index]"  placeholder="请输入uid"/>
          <el-button class="margin-l-sm" size="mini" type="danger" @click="delTags(index)">-</el-button>
        </div>
        <el-button size="mini" @click="addTags">+</el-button>
      </el-form-item>

      <el-form-item label="课节" prop="section" :rules="[
          { required: true,trigger: 'blur', message: '课节不能为空'},
          { type: 'number', message: '课节必须是数字', trigger: ['blur', 'change'] }
          ]">
        <el-input v-model.number="form.section" style="width: 240px"  clearable placeholder="第几节" />
      </el-form-item>

      <el-form-item label="开始上课时间" prop="start_at" :rules="[{ required: true,trigger: 'blur', message: '开始上课时间不能为空'},]">
        <el-date-picker
            style="width: 240px"
            v-model="form.start_at"
            :picker-options="pickerOptions"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间">
        </el-date-picker>

        <el-popover
            placement="right"
            title=""
            width="200"
            trigger="hover"
            content="关注【有帆童学】微信公众号，可在开始上课前30分钟收到课程提醒通知">
          <span  slot="reference" style="color: #909399" class="el-icon-info margin-l-sm"></span>
        </el-popover>
      </el-form-item>

      <el-form-item label="课程时长" prop="time" :rules="[{ required: true,trigger: 'blur',message: '姓名不能为空'},]">
        <el-select v-model="form.time" size="small" style="width: 240px" placeholder="请选择校区">
          <el-option label="15分钟" value="15"></el-option>
          <el-option label="30分钟" value="30"></el-option>
          <el-option label="45分钟" value="45"></el-option>
          <el-option label="60分钟" value="60"></el-option>
          <el-option label="75分钟" value="75"></el-option>
          <el-option label="90分钟" value="90"></el-option>
          <el-option label="120分钟" value="120"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="备注" >
        <el-input v-model="form.remark" :rows="5" type="textarea" style="width: 240px"  clearable />
      </el-form-item>

      <el-form-item>
        <el-button size="mini" @click="$router.back()">取消</el-button>
        <el-button size="mini" @click="submit" :loading="loading" type="primary">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {  },
  data() {
    return {
      loading: false,
      image: '',
      icon: '',
      createdCourseLoading:false,
      createdCourseVisible:false,
      courseName:'',
      courseList:[],
      teacherList:[],
      pickerOptions:{
        shortcuts:[
          {
            text:'9:00',
            onClick(picker){
              let t
              let s = ' 09:00:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'9:30',
            onClick(picker){
              let t
              let s = ' 09:30:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'10:00',
            onClick(picker){
              let t
              let s = ' 10:00:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'10:30',
            onClick(picker){
              let t
              let s = ' 10:30:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'11:00',
            onClick(picker){
              let t
              let s = ' 11:00:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'11:30',
            onClick(picker){
              let t
              let s = ' 11:30:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'12:00',
            onClick(picker){
              let t
              let s = ' 12:00:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'12:30',
            onClick(picker){
              let t
              let s = ' 12:30:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'13:00',
            onClick(picker){
              let t
              let s = ' 13:00:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'13:30',
            onClick(picker){
              let t
              let s = ' 13:30:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'14:00',
            onClick(picker){
              let t
              let s = ' 14:00:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'14:30',
            onClick(picker){
              let t
              let s = ' 14:30:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'15:00',
            onClick(picker){
              let t
              let s = ' 15:00:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'15:30',
            onClick(picker){
              let t
              let s = ' 15:30:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'16:00',
            onClick(picker){
              let t
              let s = ' 16:00:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'16:30',
            onClick(picker){
              let t
              let s = ' 16:30:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'17:00',
            onClick(picker){
              let t
              let s = ' 17:00:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'17:30',
            onClick(picker){
              let t
              let s = ' 17:30:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'18:00',
            onClick(picker){
              let t
              let s = ' 18:00:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'18:30',
            onClick(picker){
              let t
              let s = ' 18:30:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'19:00',
            onClick(picker){
              let t
              let s = ' 19:00:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'19:30',
            onClick(picker){
              let t
              let s = ' 19:30:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'20:00',
            onClick(picker){
              let t
              let s = ' 20:00:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'20:30',
            onClick(picker){
              let t
              let s = ' 20:30:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },
          {
            text:'21:00',
            onClick(picker){
              let t
              let s = ' 21:00:00'
              if(!picker.visibleDate){
                let date = new Date()
                t = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+s
              }else{
                t = picker.visibleDate+s
              }
              picker.$emit('pick', t);
            }
          },


        ],
      },
      form: {
        course_id: '',
        school: '',
        section: '',
        helpers:[],
        time: '',
        teacher_uid: '',
        start_at: '',
        remark: '',
      }
    }
  },
  methods: {
    ...mapActions('courseScheduling', [
        'addCourseSchedulingCourse',
        'getCourseSchedulingCourse',
        'getCourseSchedulingTeacherList',
        'setScheduling',
    ]),
    createdCourse(){
      this.createdCourseVisible = true
      this.createdCourseLoading = true
      this.addCourseSchedulingCourse({name:this.courseName}).then(res => {
        if(res.ret==0){
          this.getCourseList()
          this.$message.success('创建成功')
          this.createdCourseVisible = false
        }else{
          this.$message.success('创建成失败')
        }
        console.log(res)
      }).finally(()=>{
        this.createdCourseLoading = false
      })
    },

    submit() {
      console.log(this.form)
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true
          this.setScheduling(this.form).then(res =>{
            if (res.ret == 0){
              this.$message.success('新增成功！')
              this.$router.push('/courseScheduling')
            }
          }).finally(()=>{
            this.loading = false
          })
        }
      });
    },
    getCourseList(){
      this.getCourseSchedulingCourse({status:1,pageSize:1000}).then(res => {
        console.log(res)
        this.courseList = res.data.list
      })
    },
    getTeacherList(){
      this.getCourseSchedulingTeacherList({pageSize:'500'}).then(res => {
        this.teacherList = res.data.list
      })
    },
    addTags(){
      this.form.helpers.push('')
    },
    delTags(index){
      this.form.helpers.splice(index,1)
    },
    selectCourse(id){
      this.courseList.forEach(item => {
        if(id === item.id){
          this.form.helpers = item.teachers == null ? [] : item.teachers
        }
      })
    }
  },
  mounted() {
    this.getCourseList()
    this.getTeacherList()
  }
}
</script>
